import styled, { css } from 'styled-components';
import { SaveButton } from "../../InputGoals/styles";
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';
const OverViewContainer = styled.div`
  display: block;
  background: #fff;
  width: 100%;
  border-radius: 6px;
  padding: 30px 25px 16px 33px;
  margin-top: 20px;
  float: left;
  box-shadow: ${({ boxShadow }) => boxShadow && '0px 5px 10px rgba(0, 0, 0, 0.08)'};
  @media (max-width: 400px){
    padding: 30px 7px 16px 20px
  }
`;
const ChallengeName = styled.div`
  display: inline-block;
  background: linear-gradient(0deg, #F6F6F8, #F6F6F8);
  border-radius: 20.6009px;
  padding: 6px 19px;
  > span {
    font-family: ${MediumFont};
    font-size: 13.1845px;
    line-height: 15px;
    text-transform: uppercase;
    color: #484856;
  }
`;
const Description = styled.div`
  color: rgb(153,153,153);
  height: auto;
  padding-top: 15px;
  clear: both;  
  > span {
    font-family: ${MediumFont};
    font-size: 16px;
    line-height: 18px;
    color: #484856;
  }
  
  > div {
    font-size: 16px;
    line-height: 26px;
    padding: 10px 0;
    color: #484856;
    text-align: justify;
    word-break: break-word;
  }
`;
const AddCalenderButton = styled(SaveButton)`
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  font-size: 12px;
  background: linear-gradient(0deg, #1E76AB, #1E76AB);
  border-radius: 25px;
  width: 175px;
  height: 35px;
  font-family: ${MediumFont};
  display: block;
  text-align: center;
  
  &:focus, &:active, &:hover {
    outline: none;
  }
  
  > i {
    margin-right: 5px;
  }
  > span {
    float: left;
  }
  > svg {
    float: left;
    margin-right: 5px;
    width: 16px;
    height: 15px;
    margin-top: 2px;
  }
  ${({ rsvpButton }) => rsvpButton && css`
    height: 34px;
    width: auto;
    padding: 0 20px;
  `}
  ${({ disabled }) => disabled && css`
    cursor: not-allowed !important;
    opacity: 0.5;
    width: auto;
    padding: 0 20px;
  `}
  ${({ joinButton }) => joinButton && css`
    @media (max-width: 770px) {
      padding: 10px 20px;
      width: auto;
      height: auto;
    }
  `}
  ${({ calendar }) => calendar && css`
    padding-left: 18px;
  `}
  @media(max-width: 400px){
    margin: 20px auto;
  }
`;
const ChallengeCompetitor = styled.div`
  display: block;
  width: 100%;
  float: left;
  padding-top: 24px;
  & > div {
    width: 100%;
  @media (max-width: 1200px) and (min-width: 1001px){
    flex-direction: column;
  }
    @media (max-width: 600px){
      flex-direction: column;
    }
  }
`;
const RankPosition = styled.div`
  width: 100%;
  @media (max-width: 1200px) and (min-width: 1001px){
    width: 100%;
    padding-right: 0px;
  }
  @media (max-width: 600px){
    width: 100%;
    padding-right: 0px;
  }
  img {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 50%
  }
`;
const CaloriesInfoContainer = styled.div`
  display: flex;
  padding: 15px 0;
  width: 100%;
  @media (max-width: 600px){
    width: 100%;
    padding-right: 0px;
    &:after {
      content: none;
      height: 0
    }
  }
  @media (max-width: 1200px) and (min-width: 1001px){
    width: 100%;
    padding-right: 0px;
    &:after {
      content: none;
      height: 0
    }
  }
  &:after {
  content: none;
  width: 2px;
  background: #93959B;
  opacity: 0.2;
  }
`;
const Total = styled.div`
  display: flex;
  width: 40%
  align-items:center;
  & > div:first-child {
    width: 34px;
    height: 34px;
    border-radius: 50%
    background: ${({ bgColor }) => bgColor ? bgColor : '#4798D9'};
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 18px;
      border-radius: unset;
      height: 18px
    }
  }
`;
const Average = styled.div`
  width: calc(60% - 1px);
  text-align: right
  padding-right:8px;
  & > span {
    font-family: Rubik-Medium;
    color: rgb(51,51,51);
    font-size: 19px;
  }
  
  & > div {
    font-family: Rubik-Regular;
    font-size: 14px;
    color: rgba(40,44,55,0.5);
  }
`;

const AboutChallenge = styled.div`
  width: 100%;  
  @media (max-width: 1200px) and (min-width: 1001px){
    width: 100%;
    padding-left: 0px;
  }
  @media (max-width: 600px){
    width: 100%;
  }
`;

const ChallengePoint = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  font-size: 18px;
  @media(max-width: 1200px) and (min-width:1001px){
    min-height: unset;
    padding-top: 10px;
  }
  @media(max-width:600px) {
    min-height: unset;
    padding-top: 10px;
  }
  font-family: ${MediumFont};
  & > div:first-child{
    font-family: ${MediumFont};
    font-size: 15px;
    color: #4798D9;
    align-self: flex-end;
    @media(max-width: 1200px) and (min-width:1001px){
      align-self: center;
    }
    @media(max-width: 600px){
      align-self: center;
    }
  }
  & > div:nth-child(2){
    font-family: ${RegularFont};
    font-size: 15px;
    color: #52525F;
    display: flex;
    align-items: center;
    padding-right: 8px;
    
    & > span {
      margin-left: 4px;
    }
  }
`;

const Participant = styled(Total)`
  align-items: center;
  cursor: pointer;
  width: calc(50% - 15px);
  & > div:first-child {
    background: #4798D9;
    color: red
  }
  & > div:nth-child(2){
    width: calc(100% - 42px);
    font-size: 14px;
    color: rgb(153,153,153);
    margin-left: 8px;
    > span {
      font-family: Rubik-Medium;
      color: rgb(51,51,51);
      font-size: 19px;
      
      @media (max-width: 500px) {
        font-size: 15px;
      }
    }
  
      @media (max-width: 500px) {
        font-size: 13px;
      }
  }
  @media (max-width: 1200px) and (min-width: 1001px){
    width: 100%;
    padding-left: 0px;
  }
  @media (max-width: 600px){
    width: 100%;
    padding-left: 0px;
  }
`;
const CoveredChallenge = styled.div`
  float: right;
  width: ${(props) => props.challengeLeaderBoard ? '100%' : 'calc(100% - 42px)'};
  margin-left: ${(props) => props.challengeLeaderBoard ? '0px' : '8px'};
  font-size: 14px;
  color: ${(props) => props.challengeLeaderBoard ? 'rgba(109, 129, 156, 0.5)' : 'rgb(153,153,153)'};
  font-family: Rubik-Medium;
  text-transform: capitalize
  > span {
    color: ${(props) => props.challengeLeaderBoard ? props.survivor ? '#6D819C' : 'rgba(109, 129, 156, 0.7)' : 'rgb(51,51,51)'};
    font-size: ${(props) => props.challengeLeaderBoard ? '15px' : '19px'}; 
    @media (max-width: 500px) {
      font-size: 15px;
    }
  }
  
  > div {
    font-family: Rubik-Regular;
    color: rgba(40,44,55,0.5);
  }
  
  @media (max-width: 500px) {
    font-size: 13px;
  }
  @media (max-width: 400px) {
    text-align: ${(props) => props.challengeLeaderBoard ? 'center' : 'left'};
    width: ${(props) => props.challengeLeaderBoard && props.survivor ? 'auto' : '100%'};
  }
  ${({ weekTotal }) => weekTotal && css`
    display: block;
    float: none;
    text-align: right;
  `};
  
  ${({ center }) => center && css`
      text-align: right;
  `}
`;
const Ranking = styled.div`
  display: flex;
  padding: ${({ padding }) => padding ? '15px 0' : '0px'};
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  font-family: ${MediumFont};
  align-items:center
  
  &:after {
    content: none;
    width: 2px;
    background: #93959B;
    opacity: 0.2; 
    height: 47px; 
  }
  @media (max-width: 1200px) and (min-width: 1001px){
    &:after {
      content: none;
      height: 0
    }
  }
  @media(max-width: 600px){
    &:after {
      content: none;
      height: 0
    }
  }
  
  & > div:first-child{
    flex-basis: ${({ minWidth }) => minWidth ? '268px' : '115px'};
    display: flex;
    align-items:center;
    
  }
  
  & > div > div:nth-child(2) {
    float: left;
    margin-left: 8px;
    & > span {
      font-family: ${MediumFont};
      font-size: ${({ minWidth }) => minWidth ? '18px' : '20px'};
      color: #1E76AB;
    }
    & > div {
    font-family: ${RegularFont};
    font-size: 14px;
    text-align: center;
    color: rgba(40, 44, 55, 0.5);    
    }
  }
  
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
const ProfileImage = styled.div`
  width: ${(props) => props.chatHeader ? '30px' : '50px'};
  height: ${(props) => props.chatHeader ? '30px' : '50px'};
  border-radius: 50%;
  float: left;
  background: #4798D9;
  align-items: center;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  img {
    float: left;
    width: ${(props) => props.chatHeader ? '30px' : '50px'};
    height: ${(props) => props.chatHeader ? '30px' : '50px'};
    border-radius: 50%;
    
    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
    }
  }
  @media (max-width: 400px) {
    float: none;
    margin: 0 auto;
  }
  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
  }
`;

const BehindContent = styled.div`
  width: ${(props) => props.challengeLeaderBoard ? '100%' : 'calc(100% - 115px)'};
  text-align: ${(props) => props.center ? 'center' : 'left'};
  font-size: 13px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  display: flex;
  justify-content: ${(props) => props.challengeLeaderBoard ? 'flex-start' : 'flex-end'};
  padding-right: ${(props) => props.challengeLeaderBoard ? '0' : '8px'};
  @media (max-width: 400px) {
    justify-content: ${(props) => props.challengeLeaderBoard ? 'center' : 'flex-end'};
  }
  > div {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-self: flex-end;
    > span {
      font-size: ${(props) => props.challengeLeaderBoard ? '14px' : '19px'};
      font-family: ${RegularFont}
      &:first-child {
        font-family: ${MediumFont};
        color: ${(props) => props.challengeLeaderBoard ? props.currentChamp ? '#7EC341' : '#FF6D4A' : 'rgb(51,51,51)'}
      }
      &:nth-child(2) {
        color: ${(props) => props.challengeLeaderBoard ? props.currentChamp ? '#7EC341' : '#FF6D4A' : 'rgb(51,51,51)'}
        & > span {
          color: #6D819C;
        }
      }
      &:nth-child(3) {
        color: #282C37;
      }
    }
    
    > div {
      text-transform: capitalize;
      display: block;
      font-family: ${RegularFont}
       white-space: normal
      & > span {
        font-family: ${MediumFont}
      }
    }
  }
  ${({ center }) => center && css`
    > div {
      text-align: right;
    }
  `}
`;
const WeeklyTabularData = styled.div`
  display: block
  clear: both;
  float: left;
  width: 100%;
  padding: 20px 0;
  & > div:last-child {
   border-radius: 0 0 6px 6px;
  }
`;
const ColumnHeader = styled.div`
  background: #4798D9;
  color: #fff;
  float: left;
  width: 100%;
  padding: 10px;
  & > div:nth-child(2) {
    text-align: center
  }
  & > div:nth-child(3) {
    text-align: right
  }
`;
const Column = styled.div`
  width: calc((100% - 10px) / 3);
  float: left;
  font-size: 14px;
  font-family: ${MediumFont};
`;
const Row = styled(ColumnHeader)`
  display: flex;
  align-items:center;
  background: ${({ bgColor }) => bgColor ? bgColor : '#fff'}; 
`;
const RowData = styled(Column)`
  font-size: ${(props) => props.bigFont ? '19px' : '14px'};
  color: ${(props) => props.color ? props.color : '#67676A'};
  font-family: ${(props) => props.bigFont ? `${MediumFont}` : `${RegularFont}`}
  & > div {
    display: inline-block;
    text-align: center;
    > span {
      display: block;
      font-size: 14px;
      color: #67676A;
      font-family: ${RegularFont}
      text-align: right;
    }
  }
`;
const TotalParticipant = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 0;
`;
const LeftDays = styled.div`
  width: calc(50% + 15px);
  text-align: right;
  padding-right: 8px;
  & > span {
    font-family: Rubik-Medium;
    color: rgb(51,51,51);
    font-size: 19px;
  }
  
  & > div {
    font-family: Rubik-Regular;
    font-size: 14px;
    color: rgba(40,44,55,0.5);
  }
`;
const RowWrapper = styled.div`
  float: left;
  overflow-y: auto;
  height: auto;
  width: 100%;
  max-height: calc(500px - 193px);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    
    @media (max-width: 500px) {
      width: 5px;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #c6c2c2;
    cursor: pointer;
    border-radius: 2.5px;
  }
  ::-webkit-scrollbar-track {
    background: #f2f0f0;
    border-radius: 2.5px;
  }
`;
const ChallengeCSVButton = styled(AddCalenderButton)`
  display: block;
  float: right;
  text-align: center;
  margin: 0;
  padding: 9px;
  border-radius: 20.6009px;
  width: auto; 
  @media (min-width:1000px) and (max-width:1200px) {
    float: none;
    margin-top: 15px;
  }
  @media (max-width: 600px) {
    float: none;
    margin-top: 15px;
  }
  > i {
    float: left;
    line-height: 1.4
  }
`;

const OverViewHeader = styled.div`
  float: left;
  width: 100%;
`;

const AchievedTeamGoal = styled.div`
  display: inline-block;
  text-align: right;
  padding-right: 8px;
  float: right;
  font-family: ${MediumFont};
  font-size: 16px;
  & > span {
    color: #4798D9;
    font-size: 19px;
  }
  & > div {
    color: #282C37;
  }
  @media (max-width:450px) {
    display: block;
    float: none;
    text-align: left;
    padding-top: 15px;
  }
`;

const PositionCard = styled.div`
width: 100%;
display: flex;
margin-bottom: 15px;
background: white;
border-radius: 6px;

> div: first-child {
  width: 75px;
  display: flex;
  .img{
    width: 60px;
    height: 60px;
    background:#005C870D;
    margin: auto;
    margin-right: 0px;
    border-radius: 6px;
    >img{
    width: 32px;
    height: 60px;
    margin: auto;
    display: flex;
    }
  }
  .profile{
    height: 60px;
    margin: auto;
    margin-right: 0px;
  }
}
> div: last-child {
  width: calc(100% - 75px);
  display: block;

  .typeValue{
    color: ${({ color }) => color ? color : '#9FC989'};
    font-family: rubik-medium;
  }
  > div: first-child {
    width: 100%;
    display: flex;
    padding: 15px 15px 8px 5px;
    justify-content: space-between;
    cursor:${({ cursor }) => cursor ? 'pointer' : ''};
    > div: first-child {
      width: 70%;
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      font-family: rubik;
      color: #005C87;
      line-height: 19px;
      padding-left: 10px;

    }
    > div: last-child {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-family: rubik-medium;
      color: #0D4270;
      color: ${({ color }) => color ? color : '#9FC989'};
      text-transform: capitalize;

    }
  }
  > div: last-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 15px 8px 10px;
    > div: first-child {
      width: ${({ width }) => width ? "100%" : '70%'};
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      font-family: rubik;
      color: #005C87;
      padding-left: 5px;
      >span{
        color: ${({ valueColor }) => valueColor ? valueColor : '#005C87'};
        display: flex;
        font-family: rubik-medium;
        >p{
          color: ${({ valueColor }) => valueColor ? valueColor : '#005C87'};
          font-family: rubik-medium;
        }
      }
    }

    > div: last-child {
      width: ${({ width }) => width ? "0%" : '30%'};
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-family: rubik-medium;
      color: ${({ color }) => color ? color : '#9FC989'};
    }
    .eventType{
      font-size: 16px;
      font-family: rubik-medium;
      color: rgb(127,67,255)
    }
    .locationPlace{
      color: #0D4270;
    }
  }
}
`;

const PositionCardV2 = styled.div`
width: 100%;
display: flex;
margin-bottom: 15px;
background: white;
border-radius: 6px;

> div: first-child {
  width: 75px;
  display: flex;
  >img{
    width: auto;
    height: 60px;
    margin: auto;
    margin: 15px 0 15px 15px;
    border-radius: 6px;
  }
  .profile{
    height: 60px;
  }
}
> div: last-child {
  width: calc(100% - 75px);
  display: block;
  > div: first-child {
    width: 100%;
    display: flex;
    padding: 15px 15px 8px 5px;
    justify-content: space-between;
    cursor:${({ cursor }) => cursor ? 'pointer' : ''};
    > div: first-child {
      width: 70%;
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      font-family: rubik;
      color: #005C87;
      line-height: 19px;
      padding-left: 10px;
    }
    > div: last-child {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-family: rubik-medium;
      color: #005C87;
      text-transform: capitalize;
    }
  }
  > div: last-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    > div: first-child {
      width: ${({ width }) => width ? "100%" : '70%'};
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      font-family: rubik;
      color: #005C87;
      padding-left: 5px;
      >span{
        font-size: 16px;
        font-family: rubik-medium;
        color: ${({ color }) => color ? color : '#005C87'};
      }
      .eventType{
        font-size: 16px;
        font-family: rubik;
        color: #005C87;
      }
    }

    > div: last-child {
      width: ${({ width }) => width ? "0%" : '30%'};
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-family: rubik-medium;
      color: ${({ color }) => color ? color : '#005C87'};
      padding-right: 15px;
    }
    .eventType{
      font-size: 16px;
      font-family: rubik-medium;
      color: #005C87
    }
    .locationPlace{
      color: #005C87;
    }
  }
}
`;


const SideHeading = styled.div`
  width: 100%;
  font-family: 'Rubik-Medium';
font-size: 24px;
color:#0D4270;
margin-bottom: 25px;
`;

const LastSync = styled.div`
  width: 100%;
  display: flex;
  background: white;
  margin-bottom: 25px;
  border-radius: 6px;
  height: 50px;
  > div: first-child {
    padding-left: 13px;
    font-family: Rubik;
    font-size: 16px;
    line-height: 18px;
    color: #005C87;
    display: flex;
    align-item center;
    margin: auto;
    margin-left: 0px;
  }
  > div: last-child {
    padding-right: 25px;
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 18px;
    color: #005C87;
    display: flex;
    align-item center;
    justify-content: flex-end;
    margin: auto;
    margin-right: 0px;
  }
`;

const EventDateContainerNew = styled.div`
  width: 100%;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  min-height: 80px;
  cursor:${({cursor }) => cursor? 'pointer' : ''};

  >div:nth-child(3){
  width: 100%;
  display: flex;

div: first-child {
 display: flex;
 justify-content: flex-start;
width: 50%;

    font-size: 16px;
    font-family: rubik;
    color: #005C87;
margin-left: 15px;
padding: 8px 0px;
}
div: last-child {
 display: flex;
 justify-content: flex-end;
width: 50%;

    font-size: 16px;
    font-family: 'rubik-medium';
    color: #005C87;
margin-right: 25px;
padding: 8px 0px;
}
}

>div:nth-child(5){
  width: 100%;
  display: flex;

div: first-child {
display: flex;
justify-content: flex-start;
width: 50%;

  font-size: 16px;
  font-family: rubik;
  color: #005C87;
margin-left: 15px;
padding: 8px 0px;
}
div: last-child {
display: flex;
justify-content: flex-end;
width: 50%;
margin-right: 25px;

  font-size: 16px;
  font-family: 'rubik-medium';
  color: #005C87;
  padding: 8px 0px;

}


}
`;

const WeeklyStreak = styled.div`
width: 100%;
display: flex;
div: first-child {
display: flex;
justify-content: flex-start;
width: 50%;

font-size: 16px;
font-family: rubik;
color: #005C87;
margin-left: 15px;
padding: 8px 0px;
}
div: last-child {
display: flex;
justify-content: flex-end;
width: 50%;
margin-right: 25px;

font-size: 16px;
font-family: 'rubik-medium';
color: #005C87;
padding: 8px 0px;
>img{
  width: 22px;
  height: 22px;
  margin-left: 6px;
}
}
`;

const InformationCard = styled.div`
width: 100%;
display: flex;
margin-bottom: 15px;
background: white;
border-radius: 6px;
.image{
  width: 60px;
  height: 60px;
  margin: 15px;
  >img{
    width:60px;
    height:60px;
    border-radius:3px;
  }
}
.card-wrapper{
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
.first-card{
  display: flex;
  width: 100%;
  margin-bottom:10px;
  .field{
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color:#005c87;
    display: flex;
    margin:auto;
    margin-left:0px;
  }
  .value{
    display: flex;
    margin:auto;
    margin-right:15px;
    font-family: "Rubik-Medium";
    font-size: 16px;
    line-height: 20px;
    color:#005C87;
    .type{
      font-family: "Rubik";
      font-size: 16px;
      line-height: 20px;
      color:rgba(0, 47, 71, 1);
    }
  }
}
.last-card{
  display: flex;
  width: 100%;
  margin-top:10px;
  .field{
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color:#005c87;
    display: flex;
    margin:auto;
    margin-left:0px;
  }
  .value{
    display: flex;
    margin:auto;
    margin-right:15px;
    font-family: "Rubik-Medium";
    font-size: 16px;
    line-height: 20px;
    color:#005C87;
    .type{
      font-family: "Rubik";
      font-size: 16px;
      line-height: 20px;
      color:rgba(0, 47, 71, 1);
    }
  }
}
}

.teamChallenge{
  .field{
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color:#005C87;
  }

  .value{
    font-family: Rubik-Medium;
    font-size: 16px;
    line-height: 20px;
    color:#005C87;
  }

}
`;


const ParticipantCard = styled.div`
  width:100%;
  display:flex;
  padding: 15px;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  .image{
    width:60px;
    height:60px;
    >img{
      width:60px;
      height:60px;
    }
  }
  .text{
    display:flex;
    font-size: 16px;
    font-family: rubik;
    color: #005C87;
    line-height: 19px;
    align-items:center;
    margin:auto;
    margin-left:15px;
  }
  .count{
    display:flex;
    font-size: 16px;
    font-family: rubik;
    color: #005C87;
    line-height: 19px;
    align-items:center;
    margin:auto;
    margin-right:0px;
  }
`;

export { OverViewContainer, ChallengeName, Description, AddCalenderButton, ChallengeCompetitor, RankPosition, CaloriesInfoContainer, Total, Average, AboutChallenge, ChallengePoint, Participant, CoveredChallenge, Ranking, ProfileImage, BehindContent, WeeklyTabularData, ColumnHeader, Column, Row, RowData, TotalParticipant, LeftDays, RowWrapper, ChallengeCSVButton, AchievedTeamGoal, OverViewHeader, PositionCard, SideHeading, LastSync, PositionCardV2, EventDateContainerNew, WeeklyStreak, InformationCard, ParticipantCard }